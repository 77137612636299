<template>
<div>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title">
                    <form class="filter d-flex align-items-center position-relative fs-6 text-gray-600" @submit.prevent>
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input v-model="filter.desain_id" @change="onFilter" placeholder="Search Desain ID" clearable />
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" @change="onFilter" placeholder="Pilih Folder" clearable>
                                <el-option v-for="(o, i) in listFolder" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>  
                        <div class="filter-item">
                            <span>Cari</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter" @clear="onFilter" />
                        </div>
                        <div class="d-flex">
                            <button type="submit" class="btn btn-icon btn-light-primary me-3" @click="onFilter('desain'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('desain'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </form>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button class="btn btn-active-light me-3" @click="getData()" v-show="false">Refresh</button>
                        <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                        <router-link :to="'stok_desain/history'" class="btn btn-primary">Riwayat</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content sticky">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th class="w-125px">Desain ID</th>
                            <th class="w-125px">Folder</th>
                            <th>Tipe Desain</th>
                            <th>Kategori Desain</th>
                            <th>Ukuran Print</th>
                            <th>HPP</th>
                            <th class="text-end w-125px" v-for="(data, i) in tableStore" :key="i">
                                {{ data.name }}
                            </th>
                            <th class="text-end w-125px">Total</th>
                            <th class="text-end w-125px">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i" class="odd">
                            <td scope="row">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_desain+item.gambar">
                            </td>
                            <td>{{ item.desain_id }}</td>
                            <td>{{ item.folder || '-' }}</td>
                            <td>{{ item.tipe_desain || '-' }}</td>
                            <td>
                                <el-popover
                                    placement="top"
                                    :width="250"
                                    trigger="click"
                                    :auto-close="8000"
                                >
                                    <template #reference>
                                        <button href="javascript:void(0)" class="btn btn-light btn-active-light-primary btn-sm" 
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" 
                                                @click="desainKategori(item.desain_id)" title="Klik untuk melihat detail kategori">Lihat</button>
                                    </template>
                                    <span v-if="loadingPopover" class="text-gray-700 text-center">Loading..</span>
                                    <span v-if="!loadingPopover">{{ listDesainKategori.length ? listDesainKategori.map(o => o.name).join([separator = ', ']) : 'Tidak ada' }}</span>
                                </el-popover>
                            </td>
                            <td>{{ item.print_size + '(' + item.print_size_detail + ')' }}</td>  
                            <td>{{ formatIDR(item.hpp, { prefix: 'Rp' }) }}</td>    
                            <td class="text-center" v-for="(data, i) in tableStore" :key="i">
                                <div :title="'Add stock to '+data.store_name" class="ms-auto w-50px p-1 cursor-pointer bg-gray-200" @click="addStock(item, item.warehouse.find(o => o.id == data.id))">{{ item.warehouse.find(o => o.id == data.id).qty }}</div>
                            </td>
                            <td class="text-end">{{ item.total }}</td>
                            <td class="text-end">
                                <div class="d-flex justify-content-end">
                                    <a href="javascript:void(0)" @click="addStock(item, item.warehouse.find(o => o.id == 1))" class="btn btn-primary btn-sm">
                                        <i class="ms-2 fas fa-plus"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-5">
                <el-pagination 
                    background 
                    :pager-count="4"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <el-dialog v-model="modalVisible" :title="tempData.store_name" custom-class="modal-stok" :close-on-click-modal="false" width="400px">
            <form @submit.prevent="onSubmit">
                <div class="me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <div class="mb-4"><span class="fs-6 text-uppercase">stok saat ini: {{ tempData.qty }}</span></div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fw-bold mb-2">
                            <span>{{ tempData.name }}</span>
                        </label>
                        <input type="number" min="0" class="form-control" placeholder="Tambah" v-model="tempData.so_qty" />
                    </div>
                     <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">Keterangan (Optional)</label>
                        <input type="text" class="form-control" placeholder="Isi keterangan" v-model="tempData.note" />
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="button" class="btn btn-light me-3" @click="modalVisible = false">Cancel</button>
                    <button
                        type="submit"
                        class="btn btn-primary"
                        data-bs-toggle="button"
                        @click="onSubmit"
                        :data-kt-indicator="loadingButton ? 'on' : 'off'"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form>
        </el-dialog>
    </div>
    
    <div class="bg-white mt-5 rounded-2">
        <div class="summary-total">
            <h4 class="mb-5 text-gray-700 text-uppercase">Ringkasan Stok Desain</h4>
            <div class="d-flex fs-4 fw-bolder text-gray-700">
                <div class="d-flex flex-column me-5">
                    <span class="text-uppercase">TOTAL HPP</span>
                    <span class="text-gray-400">Total Stok</span>
                </div>
                <div class="d-flex flex-column">
                    <span><span class="me-2">:</span>{{ listSummaryPrintSize.map(o => o.total_hpp).reduce((prev, next) => prev + next, 0) > 0 ? formatIDR(listSummaryPrintSize.map(o => o.total_hpp).reduce((prev, next) => prev + next, 0), { prefix: 'Rp'}) : 0 }}</span>
                    <span class="text-gray-400"><span class="me-2">:</span>{{ listSummaryPrintSize.map(o => o.total_qty).reduce((prev, next) => prev + next, 0) }}</span>
                </div>
            </div>  
        </div>
    </div>

    <div class="bg-white mt-5 rounded-2">
        <div class="summary-list-fixed">
            <div class="fs-4 fw-bolder text-gray-700" v-for="o in listSummaryPrintSize" :key="o">
                <div class="d-flex">
                    <span class="text-uppercase">Total HPP {{ o.print_size }}</span>
                    <span class="ms-auto">{{ o.total_hpp ? formatIDR(o.total_hpp, { prefix: 'Rp'}) : 0 }}</span>
                </div>
                <div class="d-flex text-gray-400">
                    <span>Stok</span>
                    <span class="ms-auto">{{ o.total_qty }}</span>
                </div>
            </div>
        </div>
    </div>
    <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
        <inner-image-zoom :src="viewImageSrc" />
    </el-dialog>
</div>
</template>

<style>
    .modal-stok .el-dialog__body {
        padding-top: 0 !important;
    }
</style>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElLoading, Elpagination } from 'element-plus'
import InnerImageZoom from 'vue-inner-image-zoom';
import filter from "@/core/services/Filter";
import { useRoute } from "vue-router"
//import { InfoFilled } from '@element-plus/icons-vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({
    components: { 
        //Datatable,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const store = useStore()
        const img_url_desain = ref(process.env.VUE_APP_IMAGE_DESAINS)
        const route = useRoute()

        const loadingTable = ref(true)
        const loadingButton = ref(false)
        const disabledButton = ref(true)
        const loadingPopover = ref(false)

        const modalVisible = ref(false)
        const modalTitle = ref('')
        
        const tempData = reactive({
            raw_product_id: null,
            store_id: null,
            store_name: null,
            qty: null,
            total_qty: null,
            so_qty: null,
            note: '',
        })

        const tableData = reactive([])
        const totalRecordCount = ref(0)
        const tableRef = ref(0)
        const tableStore = reactive([])

        const addStock = (data, warehouse) => {
            modalVisible.value = true
            tempData.raw_product_id = data.raw_product_id
            tempData.name = 'DESAIN ID - '+data.desain_id
            tempData.warehouse_id = warehouse.id
            tempData.warehouse = warehouse.name
            tempData.qty = warehouse.qty
            tempData.total_qty = data.total
            tempData.so_qty = null
            tempData.note = ''
        }

        const listStore = reactive([])
        const listDesainKategori = reactive([])

        const desainKategori = async (id) => {
            loadingPopover.value = true
            
            listDesainKategori.splice(0)
            
            await ApiService.setHeader();
            await ApiService.get("desain_kategori/"+id)
            .then(({ data }) => {
                Object.assign(listDesainKategori, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list desain_kategori');
            });

            loadingPopover.value = false
        }
                
        const filterActive = ref(false)
        const filter = reactive({
            category_id: null,
            desain_id: null,
        })
                
        const listFolder = computed(() => store.getters.getListRawFolder)

        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            store.dispatch(Actions.ADD_FILTER, filter);

            let dataFilter = {
                path: route.path,
                filter: JSON.parse(JSON.stringify(filter))
            }

            filterActive.value = false

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
          // if(Object.values(filter).every(o => o == null || o.length == 0)) {
          //   return
          // }

          loadingTable.value = true

          Object.keys(filter).map(key => filter[key] = null)

          filterActive.value = false
          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
            await getData()
            //loadingTable.value = false
        }

        const onSubmit = async() => {
            try {
                loadingButton.value = true

                let setData = {
                    raw_product_id: tempData.raw_product_id,
                    warehouse_id: tempData.warehouse_id,
                    qty: tempData.so_qty,
                    note: tempData.note,
                }

                await ApiService.post("inventory/create_so_desain", setData)
                .then(({ data }) => {
                    
                    getData()

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })

                    modalVisible.value = false
                })
                .catch(({ response }) => {
                    loadingButton.value = false
                    Swal.fire({
                        text: "Failed Create New Stock Opname",
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                console.error(e)
            }

            loadingButton.value = false
        }
        
        const getData = async() => {
            try {
                tableData.splice(0)
                loadingTable.value = true

                let page = filter.page ? '?page=' + filter.page : '?page=1';
                let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';
                let folder = filter.folder ? '&folder=' + filter.folder : '';
                let search = filter.search ? '&search=' + filter.search : '';
                
                await ApiService.post("inventory/stok_desain" + page + desain_id + folder + search)
                .then(({ data }) => {
                    pagination.value = data.data.pagination
                    
                    Object.assign(tableData, data.data.data)

                    // Get table dynamic cols of store
                    Object.assign(tableStore, tableData[0].warehouse)
                })
                .catch(({ response }) => {
                    console.error(response);
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const listSummaryPrintSize = reactive([])

        const summaryStokDesain = () => {
            ApiService.setHeader();
            ApiService.get("inventory/summary_stok_desain")
            .then(({ data }) => {
                Object.assign(listSummaryPrintSize, data)
            })
            .catch(({ response }) => {
                console.log('Error getting sumarry stok polos');
            });
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = 'DESAIN ID - '+data.desain_id
            viewImageVisible.value = true
            viewImageSrc.value = img_url_desain.value +data.gambar
        }

        onMounted( async() => {
            setCurrentPageBreadcrumbs("Inventory - Stok Desain", ["Stok Desain"]);
        })

        onBeforeMount(() => {
            Object.assign(filter, store.getters.getFilter);

            getData()
            summaryStokDesain()

            store.dispatch(Actions.LIST_RAW_FOLDER);
        })

        watch(() => tempData.so_qty, (val) => {
            val || val > 0 ? disabledButton.value = false : disabledButton.value = true
        })

        return {
            loadingTable, loadingPopover, img_url_desain, modalVisible, modalTitle, disabledButton, tableRef, 
            viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            filter, filterActive, onFilter, resetFilter,
            pagination, handlePageChange,
            tableStore, tableData, getData, listSummaryPrintSize,
            addStock, tempData, listStore, listDesainKategori, desainKategori, listFolder,
            onSubmit, loadingButton,
            formatIDR,
        }
    }
})
</script>